import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import logo from '../../static/img/logo.png'
import check from '../../static/img/check.svg'
import {getUrlFromImage} from "../util/CommonUtil";
import Modal from '../components/Modal';
import YoutubeBackground from '../components/YoutubeBackground';

export class IndexPageTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalOpen: this.props.mededelingTonen,
        modalTitel: this.props.mededelingTitle ? this.props.mededelingTitle : '',
        modalContent: this.props.mededelingText ? { info: this.props.mededelingText } : '',
        modalImage: this.props.mededelingImage ? getUrlFromImage(this.props.mededelingImage) : '',
    };
  }
  
  closeModal = () => {
      this.setState({modalOpen: false, modalTitel: '', modalContent: '', modalImage: ''});
  }

  openModal = (item, titel, image) => {
    console.log(item)
      this.setState({modalOpen: true, modalTitel: titel, modalContent: item, modalImage: image});
  }

  render() {
    return (
      <div>
        <YoutubeBackground
          videoId={'7V3JB45FvIs'}
          onReady={(event) =>{
            event.target.playVideo();
            // Get the duration of the currently playing video
            const videoDuration = event.target.getDuration();
            
            // When the video is playing, compare the total duration
            // To the current passed time if it's below 2 and above 0,
            // Return to the first frame (0) of the video
            // This is needed to avoid the buffering at the end of the video
            // Which displays a black screen + the YouTube loader
            setInterval(function (){
              const videoCurrentTime = event.target.getCurrentTime();
              const timeDifference = videoDuration - videoCurrentTime;
              
              if (2 > timeDifference > 0) {
                event.target.seekTo(0);
              }
            }, 1000);
          }}
          // onStateChange={(event) => {
          //   console.log('hello world##########, ', event.data);
          //   if (event.data === 'PLAYING') {
          //     //TODO: hide videoOverlay
          //     //videoOverlay.classList.add('header__video-overlay--fadeOut');
          //   }
          // }}
          // overlay={'rgba(0,0,0,.4)'}
          nocookie={true} 
        >
          {/* YOUR CONTENT */}
        </YoutubeBackground>
        {/* <iframe style={{zIndex: 1}} title='Video tvb belorta' className="beweegpunt-video" src='https://www.youtube-nocookie.com/embed/7V3JB45FvIs?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0&fs=0' frameBorder='0' allowFullScreen></iframe> */}
        <div className="home-page" id="home-page" style={{
        background: `url(${getUrlFromImage(this.props.backgroundImage)}) center center / cover no-repeat`,
        zIndex: 22,
        position: 'relative',
      }}>
      <a class="mouseDown" href="#home-page" title="Scroll naar beneden voor meer!"><span></span></a>

      <Modal open={this.state.modalOpen}
          title={this.state.modalTitel}
          content={this.state.modalContent}
          image={this.state.modalImage}
          onModalClick={this.closeModal}/>

        <div className="body" style={{zIndex: 2}}>
          <div className="logo-section">
            <div className="logo">
              <img src={logo} alt="beweegpunt" width="640" />
            </div>
            <div className="links">
              <Link to="/team" className="btn-home with-shadow inverted">Ons team</Link>
              <Link to="/aanbod" className="btn-home with-shadow">Onze aanpak</Link>
            </div>
          </div>
          <div className="onze-aanpak" >
            <ul style={{listStyleImage: `url(${check})`}}>
              {this.props.specialiteiten.map((specialiteit, index) => (<li key={index}>{specialiteit.specialiteit}</li>))}
            </ul>
          </div>
        </div>
        <p style={{zIndex: 2}}>{this.props.info} <a href="https://www.digitalewachtkamer.be/privacymelding.aspx" target="_blank" rel="noopener noreferrer"> Privacymelding</a></p>
      </div>
      </div>
    )
  }
}


const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  // console.log('frontmatter', frontmatter)
  return (
    <Layout noFooter={true}>
      <IndexPageTemplate
        mededelingTonen={frontmatter.mededelingTonen}
        mededelingTitle={frontmatter.mededelingTitle}
        mededelingText={frontmatter.mededelingText}
        mededelingImage={frontmatter.mededelingImage}
        backgroundImage={frontmatter.backgroundImage}
        specialiteiten={frontmatter.specialiteiten}
        info={frontmatter.info  || []}
      />
    </Layout>
  )
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        specialiteiten {
            specialiteit
        }
        info
        mededelingImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 65) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mededelingText
        mededelingTitle
        mededelingTonen
      }
    }
  }
`;
